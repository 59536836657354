<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <Spinner v-if="!mounted" />
      <div class="step d-flex align-items-center mx-auto" v-else>
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>
            {{ $t("Add financial data to your profile") }}
          </h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "We want to verify if you have the means to rent a specific property. This will be based on financial data you can share via our trusted partner: Nordigen."
              )
            }}
          </p>

          <label for="">{{ $t("Select your country") }}</label>
          <Multiselect
            name="country"
            v-model="form.country"
            deselect-label="Can't remove this value"
            label="text"
            track-by="value"
            :placeholder="$t('Select country')"
            :options="countries"
            :searchable="true"
            :allow-empty="false"
            :multiple="false"
            @input="countrySelected"
            :loading="countryLoading"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.text }}
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.text }}</span>
              </div>
            </template>
          </Multiselect>

          <div v-if="!hide_bank">
            <label class="mt-3" for="">{{ $t("Select your bank") }}</label>
            <Multiselect
              name="institute"
              v-model="form.institute"
              label="name"
              :placeholder="$t('Select your bank')"
              :loading="instituteLoading"
              track-by="id"
              :options="institutes"
              :option-height="104"
              :custom-label="customLabel"
              :show-labels="false"
              :multiple="false"
            >
              <template slot="singleLabel" slot-scope="props">
                <img
                  class="option__image wh-25px mr-2"
                  v-if="props?.option?.logo"
                  :src="props.option.logo"
                  alt=""
                />
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span></span
                >
              </template>
              <template slot="option" slot-scope="props">
                <img
                  class="option__image wh-25px mr-2"
                  v-if="props?.option?.logo"
                  :src="props.option.logo"
                  alt=""
                />
                <span class="option__title">{{ props.option.name }}</span>
              </template>
            </Multiselect>
          </div>
          <small class="text-danger mt-1" v-if="error">{{ error }}</small>

          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              :disabled="(!form.country || !form.institute || submitting) && !hide_bank"
              @click="submit"
            >
              {{ $t("Next step") }}
              <b-spinner
                v-if="submitting"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
            </button>
            <!-- <button class="btn btn-outline-primary btn-block mt-3">
              {{ $t("Complete this later") }}
            </button> -->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <Card class="p-4">
            <h4 class="text-primary">{{ $t("How does this work exactly?") }}</h4>
            <p class="text-gray">
              {{
                $t(
                  "You will soon be able to log in to your banking app via a trusted partner. After issuing a temporary authorization, we can validate your income based on three recurring transactions."
                )
              }}
            </p>

            <div class="bg-light-gray p-1">
              <div class="fs-10px text-center">
                {{ $t("Nordigen is an authorized supplier and PSD2 compliant") }}
              </div>
            </div>
          </Card>
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import Multiselect from "vue-multiselect";
import Spinner from "../../../components/ui/Spinner.vue";
import Card from "../../../components/general/Card.vue";

export default {
  data() {
    return {
      countries: [],
      institutes: [],
      instituteLoading: false,
      submitting: false,
      countryLoading: false,
      form: {
        country: null,
        institute: null,
      },
      error: "",
      hide_bank: false,
      mounted: false,
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    Multiselect,
    Spinner,
    Card,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;

    if (tenant_id) {
      await this.getTenant(tenant_id);
    }

    this.getCountries();
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
    );
    document.head.appendChild(recaptchaScript);

    this.mounted = true;
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    customLabel({ name }) {
      return name;
    },
    async getCountries() {
      this.countryLoading = true;
      const { data } = await window.axios.get("/countries");
      this.countries = data.map((c) => ({ value: c.code, text: c.name }));
      this.countryLoading = false;
      this.form.country = this.countries.find(
        (c) => c.value == (this.tenant.country ? this.tenant.country : "NL")
      );
      if (this.form.country) {
        this.countrySelected();
      }
    },
    async countrySelected() {
      this.error = "";
      this.institutes = [];
      this.instituteLoading = true;
      console.log("this.form", this.form);
      if (["US", "CA"].includes(this.form.country.value)) {
        this.updateTenant({
          country: this.form.country.value,
        });
        this.getPlaidLinkToken();
      }
      window.axios
        .get(`/user/tenant/institutes/${this.form.country.value}`)
        .then((response) => {
          const { data } = response;
          this.institutes = data;
          this.instituteLoading = false;
        })
        .catch(({ message, response }) => {
          this.instituteLoading = false;
          let error = response?.data?.message ?? message;
          this.error = error.country ? `country ${error.country}` : error;
          this.hide_bank = true;
        });
    },
    async getPlaidLinkToken() {
      const { data } = await window.axios.get(
        `/user/tenant/${this.tenant.id}/plaid/create-link-token`
      );

      var handler = window.Plaid.create({
        // Create a new link_token to initialize Link
        token: data.link_token,
        // receivedRedirectUri: window.location.href,
        onLoad: function () {
          // Optional, called when Link loads
        },
        onSuccess: async (public_token, metadata) => {
          // Send the public_token to your app server.
          // The metadata object contains info about the institution the
          // user selected and the account ID or IDs, if the
          // Account Select view is enabled.
          await window.axios.post(`/user/tenant/${this.tenant.id}/plaid/save-token`, {
            plaid_public_token: public_token,
            plaid_metadata: metadata,
          });
          this.$router.push({
            name: "ExpatStep8",
            params: {
              tenant_id: this.tenant.id,
            },
          });
        },
        onExit: function (err, metadata) {
          console.log("onExit metadata", metadata);
          // The user exited the Link flow.
          if (err != null) {
            // The user encountered a Plaid API error prior to exiting.
          }
          // metadata contains information about the institution
          // that the user selected and the most recent API request IDs.
          // Storing this information can be helpful for support.
        },
        onEvent: function (eventName, metadata) {
          console.log("onExit metadata", eventName, metadata);
          // Optionally capture Link flow events, streamed through
          // this callback as your users connect an Item to Plaid.
          // For example:
          // eventName = "TRANSITION_VIEW"
          // metadata  = {
          //   link_session_id: "123-abc",
          //   mfa_type:        "questions",
          //   timestamp:       "2017-09-14T14:42:19.350Z",
          //   view_name:       "MFA",
          // }
        },
      });
      handler.open();
    },
    async submit() {
      if (this.error) {
        this.$router.push({
          name: "ExpatStepFallback",
          params: { tenant_id: this.tenant.id },
        });
        return;
      }
      this.error = "";
      this.submitting = true;
      let country = this.form.country.value;
      await window.axios
        .post(`/user/tenant/${this.tenant.id}/nordigen/get-redirect-link`, {
          institute_id: this.form.institute.id,
          country,
        })
        .then((response) => {
          location.href = response.data.link;
        })
        .catch(({ message, response }) => {
          this.submitting = false;
          this.error = response?.data?.message ?? message;
        });
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
